import CustomButton from "components/CustomButton";
// import youtubeImg from "assets/images/youtube-img.png";
// import instagramImg from "assets/images/instagram-img.png";
import facebookImg from "assets/images/facebook-img.png";
import whatsappImg from "assets/images/whatsapp-img.png";
import telegramImg from "assets/images/telegram-img.png";
import linkedinImg from "assets/images/linkedin-img.png";
import { SocialMediaScreenRoot } from "styles/styles";

const media = [
  // {
  //   text: "More then 100+ videos about trucking",
  //   imgHref: youtubeImg,
  //   imgAlt: "Youtube icon",
  //   linkHref: "",
  //   primaryLink: false,
  //   linkLabel: "Folow",
  // },
  // {
  //   text: "Join our group to get in touch with us",
  //   imgHref: instagramImg,
  //   imgAlt: "Instagram icon",
  //   linkHref: "",
  //   primaryLink: true,
  //   linkLabel: "Visit",
  // },
  {
    text: "Follow us and see results of our company",
    imgHref: facebookImg,
    imgAlt: "Facebook icon",
    linkHref: "https://www.facebook.com/profile.php?id=61555710081411",
    primaryLink: false,
    linkLabel: "Folow",
  },
  {
    text: "Follow us and see results of our company",
    imgHref: whatsappImg,
    imgAlt: "Whatsapp icon",
    linkHref: "https://call.whatsapp.com/video/RoAZn14eRqPYWQ6kf6yH7L",
    primaryLink: false,
    linkLabel: "Folow",
  },
  {
    text: "Follow us and see results of our company",
    imgHref: telegramImg,
    imgAlt: "Telegram icon",
    linkHref: "https://t.me/Dia_anaB",
    primaryLink: false,
    linkLabel: "Folow",
  },
  {
    text: "Follow us and see results of our company",
    imgHref: linkedinImg,
    imgAlt: "Linkedin icon",
    linkHref: "https://www.linkedin.com/in/bulgaru-diana-68b9a7204/",
    primaryLink: false,
    linkLabel: "Folow",
  },
];

const SocialMediaScreen = () => {
  return (
    <SocialMediaScreenRoot>
      <h2 className="title uppercase mb-8">Social media</h2>
      <p className="description mb-40">
        There are plenty of useful information we can share with you, please
        choose the best platform to follow us and always be updated.
      </p>
      <ul className="social-medial-list">
        {media.map(
          ({ text, imgHref, imgAlt, linkHref, primaryLink, linkLabel }) => (
            <li key={linkHref} className="social-media-item">
              <div
                className="social-media-icon"
                style={{
                  width: `${primaryLink ? "4.4rem" : "3.6rem"}`,
                  marginRight: `${primaryLink ? "1.2rem" : "2rem"}`,
                }}
              >
                <img src={imgHref} alt={imgAlt} />
              </div>
              <div className="social-media-text">{text}</div>
              <CustomButton
                as="a"
                href={linkHref}
                variant={
                  primaryLink ? "primary-contained-btn" : "dark-reverse-btn"
                }
                target="_blank"
                conditionalClasses="social-media-link"
              >
                {linkLabel}
              </CustomButton>
            </li>
          )
        )}
      </ul>
    </SocialMediaScreenRoot>
  );
};

export default SocialMediaScreen;
