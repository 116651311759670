import { createElement, useRef, useState } from "react";

import { AnimatePresence } from "framer-motion";
import AnimatedScreen from "components/AnimatedScreen";

import ExclusiveServiceScreen from "screens/ExclusiveServiceScreen";
import ShareExpFirstScreen from "screens/ShareExpFirstScreen";
import ShareExpSecondScreen from "screens/ShareExpSecondScreen";
import ShareExpThirdScreen from "screens/ShareExpThirdScreen";
import CdlScreen from "screens/CdlScreen";
import SocialMediaScreen from "screens/SocialMediaScreen";

import BackBtn from "components/BackBtn/BackBtn";
import ProgressIndicator from "components/ProgressIndicator";

import { Logo } from "assets/icons";
import { MainContainer } from "styles/app.style";

const comp = [
  ExclusiveServiceScreen,
  ShareExpFirstScreen,
  ShareExpSecondScreen,
  ShareExpThirdScreen,
  CdlScreen,
  SocialMediaScreen,
];

const stepCount = comp.length;

function App() {
  const [step, setStep] = useState(0);
  const showProgressIndicator = step < stepCount;
  const customerRef = useRef({
    fullName: "",
    phone: "",
    email: "",
    customerId: "",
  });

  const setCustomer = (values) => {
    customerRef.current = { ...customerRef.current, ...values };
  };
  const getCustomer = () => customerRef.current;
  const handleNextStep = (step) => {
    setStep((prevStep) => (typeof step === "number" ? step : prevStep + 1));
  };
  const handlePrevStep = (step) => {
    setStep((prevStep) => (typeof step === "number" ? step : prevStep - 1));
  };

  const stepProgress = step + 1;
  const backBtn = 1 < step ? <BackBtn onClick={handlePrevStep} /> : null;
  return (
    <MainContainer>
      <div className="relative">
        {backBtn}
        <div className="logo-wrapper">
          <Logo />
        </div>
      </div>

      {showProgressIndicator && (
        <div className="mb-32">
          <ProgressIndicator step={stepProgress} allSteps={stepCount} />
        </div>
      )}

      <AnimatePresence>
        <AnimatedScreen screenKey={step}>
          {createElement(comp[step], {
            onNextStep: handleNextStep,
            customer: getCustomer(),
            setCustomer: setCustomer,
          })}
        </AnimatedScreen>
      </AnimatePresence>
    </MainContainer>
  );
}

export default App;
