import { Formik } from "formik";
import { useSubmitForm } from "hooks/useSubmitform";
import { getPhotoSchema } from "validations";

import FileUpload from "components/FileUpload";
import infoImg from "assets/images/truck-reg-image.png";

import { SendPhotoScreenRoot } from "styles/styles";

const ShareExpThirdScreen = ({ onNextStep, customer }) => {
  const [{ loading, error }, handleSubmit] = useSubmitForm({
    method: "sendPhoto",
    path: "/nova/form/6",
    callback: successCb,
  });

  function successCb(values) {
    // setCustomer({ shareExpThirdScreen: values });
    onNextStep();
  }

  const handleSubmitPhoto = ({ truckReg }) => {
    handleSubmit({
      customerId: customer.customerId,
      truckReg,
    });
  };

  const defaultImg = (
    <div className="info-container">
      <img src={infoImg} alt="truck-registration-img" />
    </div>
  );

  return (
    <SendPhotoScreenRoot>
      <h1 className="title uppercase">Upload your Truck Registration</h1>
      <p className="description mb-40">
        For your application form we need your Truck Registration
      </p>

      <Formik
        initialValues={{
          truckReg: null,
        }}
        validationSchema={getPhotoSchema("truckReg")}
        onSubmit={handleSubmitPhoto}
      >
        {({ values, setFieldValue, setFieldTouched, handleSubmit }) => (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <FileUpload
              type="file"
              defaultImg={defaultImg}
              file={values.truckReg}
              name="truckReg"
              errorSubmit={error}
              loadingSubmit={loading}
              onChange={(e) => {
                setFieldTouched("truckReg", true);
                setFieldValue("truckReg", e.target.files[0]);
              }}
            />
          </form>
        )}
      </Formik>
    </SendPhotoScreenRoot>
  );
};

export default ShareExpThirdScreen;
