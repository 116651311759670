import { useState, Fragment } from "react";
import { useSubmitForm } from "hooks/useSubmitform";

import SendButton from "components/SendButton";
import { AutoComplete } from "components/FormFields";
import { deepEqual, getfiltered, isEmpty } from "utils";

import formDataList from "./formDataList.json";
import { AreaIcon, CalendarIcon, TimeEventIcon } from "assets/icons";
import { ShareExpScreenRoot } from "styles/styles";

const icons = {
  truckYear: <CalendarIcon />,
  area: <AreaIcon />,
  timeOnRoad: <TimeEventIcon />,
};

const ShareExpSecondScreen = ({ onNextStep, setCustomer, customer }) => {
  const [selectFields, setSelectedFields] = useState(
    customer?.shareExpSecondScreen ?? {}
  );

  const [{ loading, error }, submitData] = useSubmitForm({
    method: "updateLead",
    path: "/nova/form/3",
    callback: successCb,
  });

  function successCb() {
    setCustomer({ shareExpSecondScreen: selectFields });
    onNextStep();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const oldSelectFields = customer?.shareExpSecondScreen ?? {};
    const isEmptyOldFields = isEmpty(oldSelectFields);
    const equal = deepEqual(oldSelectFields, selectFields);

    if (isEmptyOldFields || !equal) {
      submitData({
        customerId: customer.customerId,
        step: "Share us your experience 2",
        ...selectFields,
      });
    } else {
      onNextStep();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedFields((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <ShareExpScreenRoot>
      <h1 className="title uppercase">Tell Us About Yourself</h1>
      <p className="description mb-40">
        Answer the questions so that we understand your experience and all your
        expectations.
      </p>
      <form onSubmit={handleSubmit}>
        {formDataList.map((item) => {
          const { title, name, type } = item;
          return (
            <Fragment key={name}>
              {type === "radio" && (
                <div className="form-row mb-52">
                  <div className="input-group-container">
                    <p className="input-group-name">
                      {icons[name]} {title}
                    </p>
                    <div className="input-radio-list">
                      {item.options.map(({ radioName }) => {
                        const modRadioName = radioName.replace(/\s/g, "");
                        return (
                          <div
                            key={radioName}
                            className={`input-radio-item ${
                              selectFields?.[name] === radioName
                                ? "input-radio-item--active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              value={radioName}
                              name={name}
                              id={`${name}${modRadioName}`}
                              onChange={handleChange}
                              checked={selectFields?.[name] === radioName}
                            />
                            <label htmlFor={`${name}${modRadioName}`}>
                              {radioName}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}

              {type === "autocomplete" && (
                <div className="form-row mb-52">
                  <AutoComplete
                    placeholder={item.placeholder}
                    queries={selectFields[name]}
                    setField={handleChange}
                    getfiltered={getfiltered}
                    name={name}
                  />
                </div>
              )}
            </Fragment>
          );
        })}

        <SendButton
          id="step-3"
          error={error}
          disabled={loading}
          type="submit"
          conditionalClasses={loading ? "inactive" : ""}
        >
          Continue
        </SendButton>
      </form>
    </ShareExpScreenRoot>
  );
};

export default ShareExpSecondScreen;
