import { AnimatedRangeProfitRoot } from "styles/styles";

const AnimatedRangeProfit = () => {
  return (
    <AnimatedRangeProfitRoot>
      <div className="range">
        <div className="range-layer-1"></div>
        <div className="range-layer-2"></div>
      </div>

      <div className="flex-row">
        <div className="flex-col flex-col-left">
          <div className="rec"></div>
          <div className="flex-col__secondary-text">Without Us</div>
          <div className="flex-col__main-text">
            $1,500/<span>NET</span>
          </div>
        </div>
        <div className="flex-col flex-col-centered">
          <div className="rec"></div>
          <div className="flex-col__secondary-text flex-col__secondary-text_bolder">
            With Trucking42
          </div>
          <div className="flex-col__main-text">
            $5,500/<span>NET</span>
          </div>
        </div>
        <div className="flex-col flex-col-right">
          <div className="rec"></div>
          <div className="flex-col__secondary-text">And More</div>
          <div className="flex-col__main-text flex-col__main-text_red">
            +$7,500/<span>NET</span>
          </div>
        </div>
      </div>
    </AnimatedRangeProfitRoot>
  );
};

export default AnimatedRangeProfit;
