import { Formik } from "formik";
import { getCustomerFormSchema } from "validations";
import { useSubmitForm } from "hooks/useSubmitform";

import { PhoneField, TextField } from "components/FormFields";
import SendButton from "components/SendButton";
import AnimatedRangeProfit from "components/AnimatedRangeProfit";

import {
  ExperienceIcon,
  ServicesIcon,
  ManIcon,
  GroupLikeIcon,
  DocIcon,
  TrailerIcon,
} from "assets/icons";

import truckerImg from "assets/images/trucker.jpg";
import truckerImgWebp from "assets/images/trucker.webp";
import { ExclusiveServiceRoot } from "styles/styles";

const ExclusiveServiceScreen = ({ setCustomer, onNextStep }) => {
  const [{ loading, error }, handleSubmit] = useSubmitForm({
    method: "createLead",
    path: "/nova/form/1",
    callback: (values) => {
      window.fbq("track", "Lead");
      window.dataLayer.push({ event: "formSubmit" });
      setCustomer(values);
      onNextStep();
    },
  });

  return (
    <ExclusiveServiceRoot>
      <h2 className="tt text-center mb-20">
        Exclusive service for <br />
        <span className="border-b-green">owner operators & truck drivers</span>
      </h2>
      <div className="pic-wrapper">
        <picture>
          <source srcSet={truckerImgWebp} />
          <img src={truckerImg} alt="trucker" />
        </picture>
      </div>

      <h2 className="tt text-center text-20 mb-24">
        Increase <br /> <span className="text-green">your weekly Income</span>
        <br />
        with our service
      </h2>

      <AnimatedRangeProfit />

      <div className="wrapper">
        <div className="card">
          <h3 className="card-title">
            <ExperienceIcon /> Best MC
          </h3>
          <ul className="list">
            <li className="list-item">Active MC and DOT</li>
            <li className="list-item">Insurance coverage</li>
            <li className="list-item">Best loads</li>
            <li className="list-item">IFTA stickers</li>
          </ul>
        </div>

        <div className="card">
          <h3 className="card-title">
            <ServicesIcon /> 24/7 services
          </h3>
          <ul className="list">
            <li className="list-item">Booking Team</li>
            <li className="list-item">Breakdown assistance</li>
            <li className="list-item">Driver Care Department</li>
            <li className="list-item">Safety</li>
            <li className="list-item">HOS</li>
          </ul>
        </div>

        <div className="card">
          <h3 className="card-title">
            <ManIcon /> Professional dispatcher
          </h3>
          <ul className="list">
            <li className="list-item">5+ years experience Dispatch</li>
            <li className="list-item">broker accounts</li>
            <li className="list-item">regular freight</li>
            <li className="list-item">Dry and Fee</li>
          </ul>
        </div>

        <div className="card">
          <h3 className="card-title">
            <GroupLikeIcon /> Open to negotiation
          </h3>
          <p className="card-desc">
            We prioritize working hand-in-hand with our Owner Operators, valuing
            their input and feedback in every decision.
          </p>
        </div>

        <div className="card">
          <h3 className="card-title">
            <DocIcon /> Full Transparency
          </h3>
          <p className="card-desc">
            Sending the rate confirmation from the broker to your email
          </p>
        </div>

        <div className="card">
          <h3 className="card-title">
            <TrailerIcon /> Any Trailer Type
          </h3>
          <ul className="list">
            <li className="list-item">2 car haul open</li>
            <li className="list-item">2 car haul closed</li>
            <li className="list-item">3 car haul open</li>
            <li className="list-item">3 car haul closed</li>
            <li className="list-item">Flatbad</li>
          </ul>
        </div>

        <h1 className="tt text-26">Join our Best Team</h1>
        <h2 className="tt text-20 text-green mb-20">
          To Increase your weekly Income
        </h2>
        <p className="description mb-30">
          Fill out the form and our specialists will promptly reach out to
          provide you with Exclusive Service.
        </p>

        <Formik
          initialValues={{
            email: "",
            fullName: "",
            phone: "",
          }}
          validationSchema={getCustomerFormSchema()}
          onSubmit={handleSubmit}
        >
          {({ values, handleSubmit, setFieldValue, handleBlur }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <TextField name="fullName" placeholder="Full name" />
              </div>
              <div className="form-row">
                <TextField name="email" placeholder="Email" />
              </div>
              <div className="form-row mb-50">
                <PhoneField
                  name="phone"
                  value={values.phone}
                  placeholder="Phone"
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  countryCodeEditable={false}
                />
              </div>

              <SendButton
                error={error}
                disabled={loading}
                type="submit"
                conditionalClasses={loading ? "inactive" : ""}
                id="step-1"
              >
                Click to Start Making Real Money
              </SendButton>
            </form>
          )}
        </Formik>
      </div>
    </ExclusiveServiceRoot>
  );
};

export default ExclusiveServiceScreen;
