import { Formik } from "formik";
import { getPhotoSchema } from "validations";

import { useSubmitForm } from "hooks/useSubmitform";
import FileUpload from "components/FileUpload";
import { SendPhotoScreenRoot } from "styles/styles";
import infoImg from "assets/images/cdl-illinois-img.png";

const CdlScreen = ({ customer, onNextStep }) => {
  const [{ loading, error }, handleSubmit] = useSubmitForm({
    method: "sendPhoto",
    path: "/nova/form/6",
    callback: successCb,
  });

  function successCb() {
    // setCustomer({ shareExpThirdScreen: });
    onNextStep();
  }

  const handleSubmitPhoto = ({ cdl }) => {
    handleSubmit({
      customerId: customer.customerId,
      cdl,
    });
  };

  const defaultImg = (
    <div className="info-container">
      <div className="img-shadow">
        <img src={infoImg} alt="driver-license-img" />
      </div>
    </div>
  );

  return (
    <SendPhotoScreenRoot>
      <h1 className="title uppercase">Upload your DL / CDL</h1>
      <p className="description mb-40">
        For your application form we need your driver's license / commertial
        driver's license
      </p>

      <Formik
        initialValues={{
          cdl: null,
        }}
        validationSchema={getPhotoSchema("cdl")}
        onSubmit={handleSubmitPhoto}
      >
        {({ values, setFieldValue, setFieldTouched, handleSubmit }) => (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <FileUpload
              type="file"
              defaultImg={defaultImg}
              file={values.cdl}
              name="cdl"
              errorSubmit={error}
              loadingSubmit={loading}
              onChange={(e) => {
                setFieldTouched("cdl", true);
                setFieldValue("cdl", e.target.files[0]);
              }}
            />
          </form>
        )}
      </Formik>
    </SendPhotoScreenRoot>
  );
};

export default CdlScreen;
