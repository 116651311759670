import styled from "styles";

export const MainContainer = styled.main`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 76.8rem;
  margin: 0 auto;
  padding: 2rem 1.6rem 4rem;
  display: flex;
  flex-direction: column;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */

  .relative {
    position: relative;
  }

  .w-full {
    width: 100%;
  }

  .mb-8 {
    margin-bottom: 0.8rem;
  }
  .mb-20 {
    margin-bottom: 2rem;
  }
  .mb-24 {
    margin-bottom: 2.4rem;
  }
  .mb-30 {
    margin-bottom: 3rem;
  }
  .mb-32 {
    margin-bottom: 3.2rem;
  }
  .mb-40 {
    margin-bottom: 4rem;
  }
  .mb-48 {
    margin-bottom: 4.8rem;
  }
  .mb-50 {
    margin-bottom: 5rem;
  }
  .mb-52 {
    margin-bottom: 5.2rem;
  }
  .mb-76 {
    margin-bottom: 7.6rem;
  }

  .text-20 {
    font-size: 2rem;
  }
  .text-26 {
    font-size: 2.6rem;
  }
  .text-center {
    text-align: center;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .text-green {
    color: #258d56;
  }

  .border-b-green {
    border-bottom: 2px solid #258d56;
  }

  .logo-wrapper {
    margin: 2rem 0rem 4rem;
    display: flex;
    justify-content: center;
    svg {
      width: 9.2rem;
      height: 4.4rem;
    }
  }
`;
