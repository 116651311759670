import { useState, useRef, useEffect, cloneElement } from "react";
import { useField } from "formik";

import Spinner from "components/Spinner";
import UsageButton from "components/SendButton";
import { FileUploadContainer } from "styles/styles";

const PreviewImage = ({ file }) => {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getImage = async () => {
      setLoading(true);
      let url = { file, size: file.size, name: file.name };
      if (typeof file === "string") {
        const data = await fetch(file)
          .then((res) => res.blob())
          .catch((err) => console.log(err));
        url = { file: data, size: data.size, name: "photo" };
      }
      const reader = new FileReader();
      reader.readAsDataURL(url.file);
      reader.onloadend = () => {
        setLoading(false);
        setPreview({ src: reader.result });
      };
    };
    getImage();
  }, [file]);

  return (
    <>
      <div className="spinner-wrap">{loading && <Spinner />}</div>
      {preview && !loading && (
        <img src={preview.src} alt="your-CDL" height={73} width={73} />
      )}
    </>
  );
};

const FileUpload = ({
  name,
  defaultImg,
  file,
  errorSubmit,
  loadingSubmit,
  ...rest
}) => {
  const [{ value, ...fields }, meta] = useField(name);
  const fileRef = useRef();

  const isValidPhoto = file && !meta?.error;

  return (
    <FileUploadContainer>
      <div>
        {isValidPhoto ? <PreviewImage file={file} /> : cloneElement(defaultImg)}
      </div>
      <div className="w-full relative">
        <input ref={fileRef} hidden {...rest} />

        <UsageButton
          type="button"
          variant="dark-reverse-btn"
          error={meta.touched && meta.error}
          disabled={loadingSubmit}
          onClick={() => fileRef.current.click()}
          {...fields}
        >
          Upload
        </UsageButton>

        {isValidPhoto && (
          <UsageButton
            error={errorSubmit}
            disabled={loadingSubmit}
            type="submit"
            conditionalClasses={loadingSubmit ? "inactive" : ""}
          >
            Confirm
          </UsageButton>
        )}
      </div>
    </FileUploadContainer>
  );
};

export default FileUpload;
