import { useState } from "react";
import { useSubmitForm } from "hooks/useSubmitform";
import { deepEqual, isEmpty } from "utils";

import SendButton from "components/SendButton";

import formDataList from "./formDataList.json";

import { StarIcon, TrailerIcon } from "assets/icons";
import { ShareExpScreenRoot } from "styles/styles";

const icons = {
  experience: <StarIcon />,
  preferredTrailer: <TrailerIcon />,
};

const ShareExpFirstScreen = ({ onNextStep, setCustomer, customer }) => {
  const [selectFields, setSelectedFields] = useState(
    customer?.shareExpFirstScreen ?? {}
  );

  const [{ loading, error }, submitData] = useSubmitForm({
    method: "updateLead",
    path: "/nova/form/2",
    callback: successCb,
  });

  function successCb() {
    setCustomer({ shareExpFirstScreen: selectFields });
    onNextStep();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const oldSelectFields = customer?.shareExpFirstScreen ?? {};
    const isEmptyOldFields = isEmpty(oldSelectFields);
    const equal = deepEqual(oldSelectFields, selectFields);

    if (isEmptyOldFields || !equal) {
      submitData({
        customerId: customer.customerId,
        step: "Share us your experience 1",
        ...selectFields,
      });
    } else {
      onNextStep();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedFields((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <ShareExpScreenRoot>
      <h1 className="title uppercase">Tell Us About Yourself</h1>
      <p className="description mb-40">
        Answer the questions so that we understand your experience and all your
        expectations.
      </p>
      <form onSubmit={handleSubmit}>
        {formDataList.map((item) => {
          const { title, name } = item;
          return (
            <div key={name} className="form-row mb-52">
              <div className="input-group-container">
                <p className="input-group-name">
                  {icons[name]} {title}
                </p>
                <div className="input-radio-list">
                  {item.options.map(({ radioName }) => {
                    const modRadioName = radioName.replace(/\s/g, "");
                    return (
                      <div
                        key={radioName}
                        className={`input-radio-item ${
                          selectFields?.[name] === radioName
                            ? "input-radio-item--active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          value={radioName}
                          name={name}
                          id={`${name}${modRadioName}`}
                          onChange={handleChange}
                          checked={selectFields?.[name] === radioName}
                        />
                        <label htmlFor={`${name}${modRadioName}`}>
                          {radioName}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}

        <SendButton
          id="step-2"
          error={error}
          disabled={loading}
          type="submit"
          conditionalClasses={loading ? "inactive" : ""}
        >
          Continue
        </SendButton>
      </form>
    </ShareExpScreenRoot>
  );
};

export default ShareExpFirstScreen;
