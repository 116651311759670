import cities from "data/cities.json";
import options from "data/options.json";
import states from "data/states.json";

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["bytes", "kb", "mb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getfiltered = (type, query) => {
  let array = null;
  switch (type) {
    case "residentState":
      array = filterStates(query);
      break;
    case "nativeCountry":
      array = filterCountries(query);
      break;
  }
  return array;
};

const filterCities = (query) => {
  const queryCities = query.split(", ")[0];
  return Object.keys(cities).reduce((acc, state) => {
    const filterCities = cities[`${state}`]
      .filter((city) => city.toLowerCase().includes(queryCities.toLowerCase()))
      .map((city) => `${city}, ${state}`);
    return [...acc, ...filterCities];
  }, []);
};

const filterCountries = (query) => {
  const queryCountries = query.toLowerCase();
  return options?.countries
    .filter(({ value }) => value.toLowerCase().includes(queryCountries))
    .map(({ _, value }) => value);
};

const filterStates = (query) => {
  const queryState = query.toLowerCase();
  const array = [];
  for (const { label } of states) {
    const include = label.toLowerCase().includes(queryState.toLowerCase());
    if (include) {
      array.push(label);
    }
  }
  return array;
};

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}
function isObject(object) {
  return object != null && typeof object === "object";
}

export function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}
