import { ProgressIndicatorContainer } from "styles/styles";

const ProgressIndicator = ({ step, allSteps }) => {
  const progress = (step / allSteps) * 100;
  return (
    <ProgressIndicatorContainer>
      <div className="indicator-wrap">
        <div className="indicator" style={{ width: `${progress}%` }}></div>
      </div>
      <span>
        {step}/{allSteps}
      </span>
    </ProgressIndicatorContainer>
  );
};

export default ProgressIndicator;
