import styled, { css } from "styles";

const inputStyle = css`
  width: 100%;
  height: 4rem;
  padding: 1.05rem 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.015em;
  color: var(--font-primary-color);
  caret-color: ${(props) => `${props.theme.mainColor}`};
  border-bottom: 1px solid #ccc;
  transition: 0.2s ease-in-out;
  &::placeholder {
    color: #787883;
  }
  &:focus {
    border-color: var(--font-primary-color);
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--font-primary-color) !important;
  }
  &.input-error {
    border-color: var(--error-color) !important;
  }
`;

export const InputFieldWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const PhoneInputFieldWrapper = styled(InputFieldWrapper)`
  input {
    ${inputStyle}
  }
  .phone-container {
    & > div:first-child {
      display: none;
    }
  }
`;

export const Input = styled.input`
  ${inputStyle}
`;

export const SelectField = styled.select`
  width: 100%;
  height: 4rem;
  padding: 1.05rem 0;
  font-size: 1.6rem;
  letter-spacing: -0.015em;
  color: #787883;
  background: none;
  border: none;
  border-bottom: 1px solid #ccc;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
`;

export const FieldError = styled.div`
  position: absolute;
  bottom: -1.6rem;
  font-size: 1.2rem;
  color: var(--error-color);
`;
